import React from "react";
import { BrowserView } from "react-device-detect";
import "./Categories.css";
import { Link } from "gatsby";

const LinkButton = ({ label, to }) => {
  return (
    <Link className={"link-button"} to={to}>
      {label && label.toUpperCase()}
    </Link>
  );
};

export const Categories = ({ categories, customLinks }) => {
  return (
    <BrowserView>
      <h2 className={"categories-title"}>Categories &darr;</h2>
      <div className={"categories-wrap"}>
        {customLinks &&
          customLinks.map((link) => {
            return (
              <LinkButton key={link.value} label={link.label} to={link.value} />
            );
          })}
        {categories &&
          categories.map((cat) => {
            return (
              <LinkButton
                key={cat.value}
                label={cat.label}
                to={`/category/${cat.value}`}
              />
            );
          })}
      </div>
    </BrowserView>
  );
};

export default Categories;
